import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { User } from './user.model';
import { Luser } from './luser.model'
import {Asset} from './asset.model'
import { stringify } from '@angular/compiler/src/util';
import { GPostService } from './gpost.service';
import { AlertService } from './alert.service';
import {RespData, LooseObject} from './gpost.service';

 

@Injectable ({providedIn : 'root'})
export class UserService{
    formData: LooseObject = {};
    assetList: any[] = [];
    constructor( private GPost :GPostService, private Show: AlertService){}
    // userData  : User ;
    data = [];
    signup(
first_name : string,
last_name : string,
email : string,
phone : number,
password : string,
    ){
const userData : User = {
    first_name: first_name,
    last_name : last_name,
    email : email,
    phone : phone,
    password : password
}
this.GPost.Post(userData).subscribe(responseData => {
    console.log(responseData);
})
    }
  
    theData(data : string){
        const dt = JSON.parse(data);
        return dt;
       
          }
        addAsset(AssetID : number , asset_price : number){
            const asset : Asset = {
                AssetID : AssetID,
                asset_price :  asset_price
            }
          
        }
        dddData(data : object){
            const dt = JSON.parse(JSON.stringify(data));
            const da = JSON.parse(JSON.stringify(dt))
            return da;
           
              }
              GetTrans() {
                this.Show.Loading();
                this.formData.action = "getTrans";
                this.GPost.Post(this.formData).subscribe((resp) => {
                  this.Show.Close();
            
                  if (this.GPost.IsObject(resp)) {
                    let data: RespData = resp as RespData;
                    
                    // switch (data.status) {
                    //   case "success":
                    //     let c: any[] = Array.of(data.data);
                    //     this.assetList = JSON.parse(c[0]);
                    //     break;
                    //   case "failed":
                    //     this.Show.Error(data.message);
                    //     break;
                    //   default:
                    //     this.Show.Alert("Invalid response received!");
                    //     break;
                    // }
                  }
            
                });
              }
}