<div class="card card-box mb-5">
  <div class="card-header alert-success">
    <h5 class="my-3">
     Assets List
    </h5>
  </div>

  <div class="divider"></div>

  <div class="card-body">
    <table class="table table-hover table-striped table-bordered mb-5">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Icon</th>
          <th scope="col">Asset Name</th>
          <th scope="col">Asset Type</th>
          <th scope="col">Init. Price</th>
          <th scope="col">Cur. Price</th>
          <th scope="col">Short Description</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of assetList">
          <td>{{item.sn}}</td>
          <td><img height="32" [src]="item.Icon" onError="this.src='https://track10.net/folder.png'"/></td>
          <td>{{item.AssetName}}</td>
          <td>{{item.AssetType}}</td>
          <td>{{item.Price}}</td>
          <td>{{item.CurPrice}}</td>
          <td>{{item.ShortDesc}}</td>
          <td><div class="dropdown">
  <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
   Options
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <span class="dropdown-item" >Edit Asset</span>
    <span class="dropdown-item" >Update Price</span>
    <span class="dropdown-item" >Disable Asset</span>
  </div>
</div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  </div>
