import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';

@Component({
  selector: 'app-setup-payment-rules',
  templateUrl: './setup-payment-rules.component.html',
  styleUrls: ['./setup-payment-rules.component.css']
})
export class SetupPaymentRulesComponent implements OnInit {


  constructor(private Service: GService, private GPost: GPostService, private Show: AlertService, private router: Router) { }
  formData: LooseObject = {};

  ngOnInit(): void {
    this.GetPaymentRule();
  }

  GetPaymentRule() {
    this.Show.Loading();
    this.formData.action = "GetPaymentRule";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.formData = this.Service.ToJsonObject(data.data);  
            break;
          case "failed":
          //  this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

  SaveConfig() {
    this.Show.Loading();
    this.formData.action = "SavePaymentRules";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            this.Service.ReloadState(this.router);
            break;
          case "failed":
            this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }


}
