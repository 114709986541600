<div class="card card-box mb-5">
    <div class="card-header alert-danger bg-red-lights">
      <div class="font-size-xl white" style="float:left;">
        Bulk Transaction Processing
      </div>
    </div>
    <div class="divider"></div>
    <div class="card-body">
        
      <div class="form-row">
        <div class="input-group mb-3 col-md-9">
            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" (change)="fileChangeEvent($event,'ExcelData')" class="form-control">
            <div class="input-group-append">
                <button (click)="ValidateBulkMoMo()" class="btn btn-danger" type="button">
                    <span class="btn-wrapper--label">Generate Preview</span>
                    <span class="btn-wrapper--icon">
                      <i class="fas fa-file-archive"></i>
                    </span>
                  </button>
                  </div>
          </div>
            
      </div>
        
      <hr/>
<h3>Transaction List Preview</h3>
<hr/>
      <div style="overflow-x:auto;">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover table-striped table-bordered report">
          <thead class="thead-light">
            <tr>
              <th scope="col">Phone Number</th>
              <th scope="col">Network</th>
              <th scope="col">Amount</th>
              <th scope="col">Narration</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of transList">
              <td>{{item.txtNumber}}</td>
              <td>{{item.txtNetwork}}</td>
              <td>{{item.txtAmount}}</td>              
              <td>{{item.txtNarration}}</td>              
              <td>{{item.txtRemarks}}</td>              
            </tr>
          </tbody>
        </table>
      </div>
      <div class="divider"></div>
  
        <button (click)="ProcessTransaction()" class="btn btn-danger" type="button">
          <span class="btn-wrapper--label">Process Transaction</span>
          <span class="btn-wrapper--icon">
            <i class="fas fa-file-archive"></i>
          </span>
        </button>
  
    </div>
  </div>
  