<div class="card card-box mb-5">
  <div class="card-header alert-danger bg-red-lights">
    <div class="font-size-xl white" style="float:left;">
     Topup Merchant Account
    </div>
  </div>
  <div class="divider"></div>
  <div class="card-body">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Institution Name</label><br/>
          {{formData.InstName}}
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Account Status</label><br/>
       <span class="badge badge-pill m-1 badge-{{ShowStatusClass(formData.Statuz)}}">{{ShowStatus(formData.Statuz)}}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Topup Amount (GHS)</label>
        <input type="text" [(ngModel)]="formData.Amount" class="form-control" placeholder="0.00">
      </div>
    </div>
    <div class="divider"></div>


    <div class="form-group">
      <button (click)="SaveDetails()" class="btn btn-danger" type="button">
        <span class="btn-wrapper--label">Update Details</span>
        <span class="btn-wrapper--icon">
          <i class="fas fa-file-archive"></i>
        </span>
      </button>
      <button (click)="Close()" class="btn btn-danger" style="float:right;">
        <span class="btn-wrapper--label">Cancel</span>
        <span class="btn-wrapper--icon">
          <i class="fas fa-file-archive"></i>
        </span>
      </button>

    </div>

  </div>
</div>
