import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddAssetComponent } from './add-asset/add-asset.component';
import { ListAssetComponent } from './list-asset/list-asset.component';
import { TransHistoryComponent } from './reports/trans-history/trans-history.component';
import { UserListComponent } from './user-list/user-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { SettingsComponent } from './settings/settings.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataTablesModule } from 'angular-datatables';
import { LoginComponent } from './signlog/login/login.component';
import { SignupComponent } from './signlog/signup/signup.component';
import { AddAnAssetComponent } from './add-an-asset/add-an-asset.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { CommonModule } from '@angular/common';
import { ActiveComponent } from './active/active.component';
import { InactiveComponent } from './inactive/inactive.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddMerchantComponent } from './add-merchant/add-merchant.component';
import { EditMerchantComponent } from './edit-merchant/edit-merchant.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { TopupAccountComponent } from './topup-account/topup-account.component';
import { WithdrawFundsComponent } from './withdraw-funds/withdraw-funds.component';
import { BulkTransComponent } from './bulk-trans/bulk-trans.component';
import { ForgotPasswordComponent } from './signlog/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './signlog/reset-password/reset-password.component';
import { RemittanceHistoryComponent } from './reports/remittance-history/remittance-history.component';
import { FlaggedTransactionsComponent } from './reports/flagged-transactions/flagged-transactions.component';
import { SetupPaymentRulesComponent } from './setup-payment-rules/setup-payment-rules.component';
import { ViewTransComponent } from './reports/view-trans/view-trans.component';
import { ViewRemittanceTransComponent } from './reports/view-remittance-trans/view-remittance-trans.component';
import { PendingApprovalsComponent } from './reports/pending-approvals/pending-approvals.component';
import { AuditTrailComponent } from './reports/audit-trail/audit-trail.component';
import { BlackListComponent } from './reports/black-list/black-list.component';


@NgModule({
  declarations: [
    AppComponent,
    AddAssetComponent,
    ListAssetComponent,
    TransHistoryComponent,
    UserListComponent,
    DashboardComponent,
    DashboardDetailsComponent,
    SettingsComponent,
    LoginComponent,
    SignupComponent,
    AddAnAssetComponent,
    AllUsersComponent,
    EditUserComponent,
    DeleteUserComponent,
    ActiveComponent,
    InactiveComponent,
    AddMerchantComponent,
    EditMerchantComponent,
    MerchantListComponent,
    AddUserComponent,
    TopupAccountComponent,
    WithdrawFundsComponent,
    BulkTransComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RemittanceHistoryComponent,
    FlaggedTransactionsComponent,
    SetupPaymentRulesComponent,
    ViewTransComponent,
    ViewRemittanceTransComponent,
    PendingApprovalsComponent,
    AuditTrailComponent,
    BlackListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SweetAlert2Module,
    DataTablesModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule, 
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
