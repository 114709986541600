<div class="card card-box mb-5">
  <div class="card-header alert-danger bg-red-lights">
    <div class="font-size-xl white" style="float:left;">
      Transaction History
    </div>
  </div>
  
  <div class="divider"></div>
  <div class="card-body">
    <div class="form-row reportX mb-3">
      <div class="form-group col-md-3">
      From <input class="form-control" placeholder="yyyy-mm-dd"
                     name="from" [(ngModel)]="StartDate" (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker">     
      </div>
      <div class="form-group col-md-3">
        To <input class="form-control" placeholder="yyyy-mm-dd"
                    name="to" [(ngModel)]="EndDate" (click)="dd.toggle()" ngbDatepicker #dd="ngbDatepicker">
      </div>
      <div class="form-group col-md-3">
        Merchant   
        <select class="form-control" [(ngModel)]="formData.ParentID">
          <option value="{{item.ID}}" *ngFor="let item of merchantList">{{item.InstName}}</option>
          <option value="">Consolidated</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        Status  
        <select class="form-control" [(ngModel)]="formData.Statuz" >
          <option value="A" >Success</option>
          <option value="Z">Failed</option>
          <option value="P">Pending</option>
          <option value="">Consolidated</option>
        </select>
      </div>
        <div class="form-group col-md-6">
          <br />
          <button (click)="GetTrans()" class="btn btn-danger" type="button">
            <span class="btn-wrapper--label">Generate Report</span>
            <span class="btn-wrapper--icon">
              <i class="fas fa-file-archive"></i>
            </span>
          </button>
        </div>

    </div>

    <div style="overflow-x:auto;">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover table-striped table-bordered report">
        <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">TransID</th>
            <th scope="col">AccNumber</th>
            <th scope="col">Narration</th>
            <th scope="col">Amount</th>
            <th scope="col">Trans Type</th>
            <th scope="col">Charge</th>
            <th scope="col">Merchant</th>
            <th scope="col">Trans Date</th>
            <th scope="col">Status</th>      
            <th scope="col">Action</th>
              </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of transList">
            <td>{{item.sn}}</td>
            <td>{{item.TransID}}</td>
            <td>{{item.AccNumber}}</td>
            <td>{{item.Narration}}</td>
            <td>{{item.Amount}}</td>
            <td>{{item.TransType}}</td>
            <td>{{item.Charge}}</td>
            <td>{{item.MakerID}}</td>
            <td>{{item.MakerTS}}</td>
            <td><span class="badge badge-pill m-1 badge-{{ShowStatusClass(item.Statuz)}}">{{ShowStatus(item.Statuz)}}</span></td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Options
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button class="dropdown-item "  (click)="ViewTrans(item)">View Details</button>
                  <button class="dropdown-item "  (click)="ValidateTrans(item.ID)">Validate</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
