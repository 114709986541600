<div class="card card-box mb-5">
    <div class="card-header alert-success">
      <h5 class="my-3">
       Active Users
      </h5>
    </div>
    <div class="divider"></div>
    <div class="card-body">
      <table class="table table-hover table-striped table-bordered mb-5">
        <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">First name</th>
            <th scope="col">Last name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Actions</th>
            
            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of array.data">
            <th name="user_id" #user_id scope="row">{{user.user_id}} </th>
            <td>{{user.f_name}}</td>
            <td>{{user.l_name}}</td>
            <td>{{user.user_name}}</td>
            <td>{{user.phone}}</td>
            <td>
              <div class="dropdown">
                <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Options
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <span class="dropdown-item"  (click)="open(editt, user.user_id, user.f_name, user.l_name, user.user_name , user.phone )">Edit</span>
                  <span class="dropdown-item" (click)="open(deactivate, user.user_id, user.f_name, user.l_name, user.user_name , user.phone )" >Deactivate</span>
                </div>
              </div>
            </td>
         
          </tr>
        </tbody>
      </table>
      <a routerLink="/Dashboard/inActive">See disabled users</a>
    </div>
  </div>



  
<ng-template #editt let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit user datails</h4>
    <button type="button" class="close" aria-label="Close" 
    (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="first_name">First name</label>
        <div class="input-group">
          <input
          [(ngModel)]="editData.first_name"
          type="text"
          class="form-control" 
          name="first_name" >
        </div>
      </div>
      <div class="form-group">
        <label for="last_name">Last name</label>
        <div class="input-group">
          <input
          [(ngModel)]="editData.last_name"
          type="text"
          class="form-control" 
          name="last_name" >
        </div>
      </div>
      <div class="form-group">
        <label for="phone">Phone</label>
        <div class="input-group">
          <input
          [(ngModel)]="editData.phone"
          type="tel"
          class="form-control" 
          name="new phone no" >
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <div class="input-group">
          <input
          [(ngModel)]="editData.email"
          type="email"
          class="form-control" 
          name="email" >
        </div>
      </div>
     
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="edit()">Save</button>
  </div>
</ng-template>



<hr>



<ng-template #deactivate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Deactivate user</h4>
    <button type="button" class="close" aria-label="Close" 
    (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{editData.email}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deact()" >Deactivate</button>
  </div>
</ng-template>




