<div class="card card-box mb-5">
  <div class="card-header alert-danger bg-red-lights">
    <div class="font-size-xl white" style="float:left;">
      Add Application User
    </div>
  </div>
  <div class="divider"></div>
  <div class="card-body">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Institution Name</label>
        <select class="form-control" [(ngModel)]="formData.ParentID">
          <option value="{{item.ID}}" *ngFor="let item of merchantList">{{item.InstName}}</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4">User Email</label>
        <input type="text" [(ngModel)]="formData.UEmail" class="form-control" placeholder="info@company.com">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">First Name</label>
        <input type="text" [(ngModel)]="formData.FirstName" class="form-control" placeholder="Kwame">
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4"> Last Name</label>
        <input type="text" [(ngModel)]="formData.LastName" class="form-control" placeholder="Annor">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Phone Number</label>
        <input type="text" [(ngModel)]="formData.CustPhone" class="form-control" placeholder="+233244xxxxxxx">
      </div>
    </div>
    <div class="divider"></div>

    <h3>Access Control</h3>
    <div class="form-row card-body col-md-12">
        <div class="custom-checkbox custom-control form-group col-md-4" *ngFor="let item of pageList">
          <input class="custom-control-input" id="{{item.code}}" [(ngModel)]="accessControl[item.code]" type="checkbox">
          <label class="custom-control-label" for="{{item.code}}">{{item.title}}</label>
        </div>
    </div>

    <div class="form-group">
      <button (click)="SaveDetails()" class="btn btn-danger" type="button">
        <span class="btn-wrapper--label">Save Details</span>
        <span class="btn-wrapper--icon">
          <i class="fas fa-file-archive"></i>
        </span>
      </button>


    </div>

  </div>
</div>
