<div class="card card-box mb-5">
    <div class="card-header alert-danger bg-red-lights">
      <div class="font-size-xl white" style="float:left;">
        Audit Trail
      </div>
    </div>
    
    <div class="divider"></div>
    <div class="card-body">
      <div class="form-row reportX mb-3">
        <div class="form-group col-md-3">
        From <input class="form-control" placeholder="yyyy-mm-dd"
                       name="from" [(ngModel)]="StartDate" (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker">     
        </div>
        <div class="form-group col-md-3">
          To <input class="form-control" placeholder="yyyy-mm-dd"
                      name="to" [(ngModel)]="EndDate" (click)="dd.toggle()" ngbDatepicker #dd="ngbDatepicker">
        </div>
       
          <div class="form-group col-md-6">
            <br />
            <button (click)="GetTrans()" class="btn btn-danger" type="button">
              <span class="btn-wrapper--label">Generate Report</span>
              <span class="btn-wrapper--icon">
                <i class="fas fa-file-archive"></i>
              </span>
            </button>
          </div>
  
      </div>
  
      <div style="overflow-x:auto;">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover table-striped table-bordered report">
          <thead class="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">User</th>
              <th scope="col">Activity</th>
              <th scope="col">Timestamp</th>   
                </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of transList">
              <td>{{item.sn}}</td>
              <td>{{item.MakerID}}</td>
              <td>{{item.Activity}}</td>
              <td>{{item.MakerTS}}</td>
               
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  