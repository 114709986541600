import { Component, OnInit } from '@angular/core';
import {RespData, LooseObject, GPostService} from '../gpost.service';
import { AlertService } from '../alert.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html',
  styleUrls: ['./inactive.component.css']
})
export class InactiveComponent implements OnInit {
  formData: LooseObject = {};
  array  : LooseObject = {};
  editData : LooseObject = {};
  ediData : LooseObject = {};
  closeResult = '';
  constructor(private Show: AlertService, private GPost :GPostService , private modalService: NgbModal ) { }

  ngOnInit(): void {
    this.GetUsers();
  }
  GetUsers() {
    this.Show.Loading();
    this.formData.action = "inactiveUsers";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();
  
      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        this.array.data = data.data[0];
        switch (data.status) {
          case "success":
            
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }
  
    });
  }
  react(){
    this.Show.Loading();
    this.editData.action = "reactivate";
    this.GPost.Post(this.editData).subscribe((resp) => {
      this.Show.Close();
  
      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        console.log(data);
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }
  
    });

  }



  open(content : any, id : string, first_name : string, last_name : string, email : string, phone : string  ) {
    this.editData.user_id = id;
    this.editData.first_name = first_name;
    this.editData.last_name = last_name;
    this.editData.email = email;
    this.editData.phone = phone;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
