
<div class="app-main">
    <div class="app-content p-0">
      <div class="app-content--inner d-flex align-items-center">
        <div class="flex-grow-1 w-100 d-flex align-items-center">
          <div class="bg-composed-wrapper--content py-5">
            <div class="container">
              <div class="row">
                <div class="col-lg-5 d-none d-lg-flex align-items-center">
                  <img alt="..." class="w-100 mx-auto d-block img-fluid" src="./assets/img/login.png">
                </div>
                <div class="col-lg-7 col-sm-12 d-flex align-items-center">
                  <div class="pl-0 pl-md-5">
                    <div class="text-black mt-3">
                      <span class="text-left text-sm-center">
                        <h1 class="display-3 text-lg-left text-center mb-3 font-weight-bold"> Forgot password </h1>
                        <p class="font-size-lg text-lg-left text-center mb-0 text-black-50"> To reset your password, enter your login email address to get a new password. </p>
                      </span>
                      <div>
                        <br/>
                          <div class="form-group">
                            <label>Email address</label>
                            <input class="form-control" [(ngModel)]="formData.username" placeholder="yourname@yourmail.com" type="email">
                            <a routerLink="/login">Remember your password?</a>
                          </div>
                          
                          <button class="btn btn-lg btn-second btn-block" (click)="ResetPassword()">Reset Password</button>
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  