import { Component, OnInit } from '@angular/core';
import { AlertService } from '../alert.service';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';

@Component({
  selector: 'app-list-asset',
  templateUrl: './list-asset.component.html',
  styleUrls: ['./list-asset.component.css']
})
export class ListAssetComponent implements OnInit {

  constructor(private GPost: GPostService, private Show: AlertService, private Service: GService) {

  }
  formData: LooseObject = {};
  assetList: any[] = [];
  ngOnInit(): void {
    this.GetAssets();
  }

  GetAssets() {
    this.Show.Loading();
    this.formData.action = "getAssets";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            let c: any[] = Array.of(data.data);
            this.assetList = JSON.parse(c[0]);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }
}
