<div class="card card-box mb-5">
  <div class="card-header alert-success">
    <h5 class="my-3">
      Add Assets
    </h5>
  </div>
  <div class="divider"></div>
  <div class="card-body">
       <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputEmail4">Asset Name</label>
          <input type="text" [(ngModel)]="formData.AssetName" class="form-control" placeholder="Midas Funds">
        </div>
         <div class="form-group col-md-6">
          <label for="inputEmail4"> Price</label>
          <input type="text" [(ngModel)]="formData.Price" class="form-control" placeholder="500.00">
        </div>      
      </div>
       <div class="form-row">
         <div class="form-group col-md-6">
           <label for="inputEmail4">Short Description</label>
           <input type="text" [(ngModel)]="formData.ShortDesc" class="form-control" placeholder="Midas Funds">
         </div>
         <div class="form-group col-md-6">
           <label for="inputEmail4">Asset Details</label>
           <textarea [(ngModel)]="formData.Description" class="form-control"></textarea>
         </div>
       </div>

       <div class="form-row">
         <div class="form-group col-md-4">
           <label for="inputState">Asset Type</label>
           <select [(ngModel)]="formData.AssetType" class="form-control">
             <option value="1">Mutual Fund</option>
             <option value="2"> Stock </option>
           </select>
         </div>
         <div class="form-group col-md-4">
           <label>Asset Icon</label>
           <input (change)="onSelectFile($event)" type="file" class="form-control">
         </div>
         <div class="form-group col-md-4">
           <img [src]="formData.Icon" height="100" />
         </div>

       </div>
    
    <div class="form-row">
      <button (click)="SaveAsset()" class="btn btn-primary" type="button">
        <span class="btn-wrapper--label">Save Asset</span>
        <span class="btn-wrapper--icon">
          <i class="fas fa-file-archive"></i>
        </span>
      </button>

       </div>

  </div>
</div>
