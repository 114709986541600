import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../alert.service';
import { GService } from '../../g.service';
import { GPostService, LooseObject, RespData } from '../../gpost.service';

@Component({
  selector: 'app-view-remittance-trans',
  templateUrl: './view-remittance-trans.component.html',
  styleUrls: ['./view-remittance-trans.component.css']
})

export class ViewRemittanceTransComponent implements OnInit {


  constructor(private Service: GService, private activeModal: NgbActiveModal, private GPost: GPostService, private Show: AlertService) { }
  formData: LooseObject = {};
  accessControl: any = {};
  serviceList: any[] = [];
  gatewayList: any[] = [];
  @Input() public user: any;

  ngOnInit(): void {
    this.formData = this.user as LooseObject;
    if (this.formData.AccLevel != undefined && this.formData.AccLevel.length > 1)
    {
      this.accessControl = JSON.parse(this.formData.AccLevel);
    } else {
      this.accessControl = {};
    }
    this.GetServices();
    this.GetGatewayList();
  }

  GetServices() {
    this.Show.Loading();
    this.formData.action = "ServiceList";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.serviceList = this.Service.ToJsonObject(data.data);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }
  GetGatewayList() {
    this.Show.Loading();
    this.formData.action = "GatewayList";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.gatewayList = this.Service.ToJsonObject(data.data);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }
  Close() {
    this.activeModal.close();
  }
  SaveMerchant() {
    this.Show.Loading();
    this.formData.action = "UpdateMerchant";
    this.formData.url = "api/Users";
    this.formData.AccLevel = JSON.stringify(this.accessControl);
    this.GPost.Post(this.formData).subscribe((resp) => {
    this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            break;
          case "failed":
            this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

  ShowStatus(val: string) {
    return this.Service.ShowTransStatus(val);
  }

}

