import { Component, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/alert.service';
import { GPostService, StorageService } from 'src/app/gpost.service';
import { GService } from '../../g.service';
import { LooseObject, RespData } from '../../gpost.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  formData: LooseObject = {};
  LoginData: LooseObject = {};
  assetList: any[] = [];

  constructor(private GPost: GPostService, private GService: GService, private Show: AlertService, private route: Router) { }

  ngOnInit(): void {
  }
  ResetPassword() {
    this.Show.Loading();
    this.formData.action = "ResetPassword";
    this.formData.url = "api/Auth";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();
      if (this.GPost.IsObject(resp)) {
        let data: LooseObject = resp as LooseObject;
        switch (data.status) {
          case "success":
            this.route.navigate(['/login']);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }else{
        this.Show.Alert("Invalid response data!");           
      }

    });
  }

}
