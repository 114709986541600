import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Luser } from '../../luser.model';
import {UserService} from '../../user.service';
import { LooseObject, RespData } from '../../gpost.service';
import { GPostService } from 'src/app/gpost.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/alert.service';
import   { User} from  '../../user.model'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
    UserData = {}
    formData: LooseObject = {};
    assetList: any[] = [];

  constructor(private GPost :GPostService, private Show: AlertService, private route : Router) { }

  ngOnInit(): void {
  }
  // onSignUp(UserData : User){
  // this.UserService.signup(UserData.first_name, UserData.last_name, UserData.email, UserData.phone, UserData.password)
  // }
  Login(UserData : User) {
    this.Show.Loading();
    this.formData.first_name = UserData.first_name;
    this.formData.last_name = UserData.last_name;
    this.formData.email = UserData.email;
    this.formData.phone = UserData.phone;
    this.formData.password = UserData.password;
    this.formData.action = "userSignup";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        if(data.status === "success"){
         this.Show.Success(data.message);
        } 
        
        switch (data.status) {
          case "success":
            let c: any[] = Array.of(data.data);
            this.assetList = JSON.parse(c[0]);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }
}
