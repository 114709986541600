<div class="card card-box mb-5">
    <div class="card-header alert-danger bg-red-lights">
      <div class="font-size-xl white" style="float:left;">
       Transaction Data : Transaction ID - {{formData.TransID}}
      </div>
    </div>
    <div class="divider"></div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputEmail4">Recipient Account</label>
          <input type="text" [(ngModel)]="formData.AccNumber" disabled="true" class="form-control" placeholder="Merchant Company">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Amount (GHS)</label>
          <input type="text" [(ngModel)]="formData.Amount" disabled="true" class="form-control" placeholder="info@company.com">
        </div>      
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Source Country</label>
          <input type="text" [(ngModel)]="formData.SrcCountry" disabled="true" class="form-control" placeholder="info@company.com">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputEmail4">External Reference </label>
          <input type="text" [(ngModel)]="formData.Reference" disabled="true" class="form-control">
        </div>
          <div class="form-group col-md-4">
          <label for="inputEmail4">Source Currency</label>
          <input type="text" [(ngModel)]="formData.SrcCurrency" disabled="true" class="form-control" placeholder="Merchant Company">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Source Amount</label>
          <input type="text" [(ngModel)]="formData.SrcAmount" disabled="true" class="form-control" placeholder="info@company.com">
        </div>          
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputEmail4">Transaction ID</label>
          <input type="text" [(ngModel)]="formData.TransID" disabled="true" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Transaction Date</label>
          <input type="text" [(ngModel)]="formData.MakerTS" disabled="true" class="form-control" placeholder="02XXXXXXXXXX">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> External Reference</label>
          <input type="text" [(ngModel)]="formData.Reference" disabled="true" class="form-control" placeholder="02XXXXXXXXXX">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputEmail4">Sender Name</label>
          <input type="text" [(ngModel)]="formData.SenderName" disabled="true" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Sender Contact</label>
          <input type="text" [(ngModel)]="formData.SenderContact" disabled="true" class="form-control" placeholder="02XXXXXXXXXX">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Sender Address</label>
          <input type="text" [(ngModel)]="formData.SenderAddress" disabled="true" class="form-control" placeholder="02XXXXXXXXXX">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputEmail4">Beneficiary Name</label>
          <input type="text" [(ngModel)]="formData.BeneficiaryName" disabled="true" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Beneficiary Contact</label>
          <input type="text" [(ngModel)]="formData.BeneficiaryContact" disabled="true" class="form-control" placeholder="02XXXXXXXXXX">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Beneficiary Address</label>
          <input type="text" [(ngModel)]="formData.BeneficiaryAddress" disabled="true" class="form-control" placeholder="02XXXXXXXXXX">
        </div>
      </div>
      <div class="form-row">       
        <div class="form-group col-md-12">
          <label for="inputEmail4"> Purpose of Transfer</label>
          <textarea [(ngModel)]="formData.PurposeTrans" disabled="true" class="form-control" ></textarea>
        </div>      
      </div>
      <div class="form-row">
         <div class="form-group col-md-6">
          <label for="inputEmail4"> Source IP Address</label>
          <input type="text" [(ngModel)]="formData.IPAddress" disabled="true" class="form-control" >
        </div>
        <div class="form-group col-md-6">
          <label for="inputEmail4"> Transaction Status</label>
          <input type="text" [value]="ShowStatus(formData.Statuz)" class="form-control" disabled="true">
        </div>
      </div>
      
  
  
      <div class="form-group">
       
        <button (click)="Close()" class="btn btn-danger" style="float:right;">
          <span class="btn-wrapper--label">Cancel</span>
          <span class="btn-wrapper--icon">
            <i class="fas fa-file-archive"></i>
          </span>
        </button>
  
      </div>
  
    </div>
  </div>
  