<div>
  <form
  #assetData="ngForm"
  (ngSubmit)="onAddAsset(assetData.value)"
  >
<div class="card card-box mb-5">
  <div class="card-header alert-success">
    <h5 class="my-3">
      Add Assets
    </h5>
  </div>
  <div class="divider"></div>
  <div class="card-body">
       <div class="form-row">
        <div class="form-group col-md-6">
         <label for="inputEmail4">Asset ID</label>
          <input 
          type="text" 
          class="form-control" 
          placeholder="Midas Funds"
          required
          ngModel
          name="AssetID"
          >
        </div>
         <div class="form-group col-md-6">
          <label for="inputEmail4"> Price</label>
          <input 
          type="text" 
          class="form-control"
          placeholder="500.00"
          required
          ngModel
          name="asset_price"
          >
        </div>      
      </div>
    <div class="form-row">
      <button 
            class="btn btn-primary"
            type="submit"
          >
      >
        <span class="btn-wrapper--label">Save Asset</span>
        <span class="btn-wrapper--icon">
          <i class="fas fa-file-archive"></i>
        </span>


      </button>

       </div>

  </div>
</div>
</form>
</div>