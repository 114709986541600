
<div class="app-main">
  <div class="app-content p-0">
    <div class="app-content--inner d-flex align-items-center">
      <div class="flex-grow-1 w-100 d-flex align-items-center">
        <div class="bg-composed-wrapper--content py-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 d-none d-lg-flex align-items-center">
                <img alt="..." style="width:300px !important;" class="w-100 mx-auto d-block img-fluid" src="./assets/img/GA.jpg">
              </div>
              <div class="col-lg-7 col-sm-12 d-flex align-items-center">
                <div class="pl-0 pl-md-5">
                  <div class="text-black mt-3">
                    <span class="text-left text-sm-center">
                      <h1 class="display-3 text-lg-left text-center mb-3 font-weight-bold"> Login to your account </h1>
                      <p class="font-size-lg text-lg-left text-center mb-0 text-black-50"> This grants you access to the admin dashboard. Login below to continue. </p>
                    </span>
                    <div>
                      <br/>
                        <div class="form-group">
                          <label>Email address</label>
                          <input class="form-control" [(ngModel)]="formData.username" placeholder="yourname@yourmail.com" type="email">
                        </div>
                        <div class="form-group mb-4">
                          <div class="d-flex justify-content-between">
                            <label>Password</label>
                            <a routerLink="/forgot-password">Forgot password?</a>
                          </div>
                          <input class="form-control" [(ngModel)]="formData.pswd" placeholder="Enter your password" type="password">
                        </div>
                        <button class="btn btn-lg btn-second btn-block" (click)="Login()">Login</button>
                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
