import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { GService } from './g.service';

@Injectable({
  providedIn: 'root'
})
export class GPostService {
  public LoginData: LooseObject = {};
  baseURL: string = this.GetUrl();
  constructor(private GService: GService, private http: HttpClient, private logs: LoggerService, private Show: AlertService) { }
  IsObject(val: any) {
      if (val === null) { return false; }
      return ((typeof val === 'function') || (typeof val === 'object'));
  }
  Post(sendz: any) {
    let urlx = "";
    if (sendz.url != undefined) {
      urlx = this.baseURL + sendz.url;
    }
    try {
      this.LoginData = this.GService.GetLogin() as LooseObject;
      sendz.UserID = this.LoginData.UserID;
      sendz.ApiKey = this.LoginData.ApiKey;
    } catch {}
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(sendz);
    return this.http.post<RespData>(urlx, body, { headers: headers, observe: 'response' })
      .pipe(
        map((data) => {
          if(data.statusText == "OK") {
        return data.body;
          } else {
            let mData = new RespData();
            mData.status = "failed";
            mData.message = "An Error occured with request";
            return mData;
          }
        }),
        catchError((err) =>  {
          this.logs.log(err);
          var mData = {
            status: "failed",
            message: "An Error Occured!"
          }
          return JSON.stringify(mData);
        }
       ));       
  }
  PostX(sendz: any) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(sendz);
    let urlx = "";
    if (sendz.url != undefined) {
      urlx = this.baseURL + sendz.url;
    }
    sendz.UserID = this.LoginData.UserID;
    sendz.ApiKey = this.LoginData.ApiKey;
    return this.http.post(urlx, body, { headers: headers, observe: 'response'})      
      .pipe(
        map((data) => {
          if (data.statusText == "OK") {
            return data.body;
          } else {
            const mData = {
              status: "failed",
              message: "Server response error!"
            }
            return mData;
          }
        }),
        catchError((err) =>  {
          this.logs.log(err);
          var mData = {
            status: "failed",
            message: "An Error Occured!"
          }
          return JSON.stringify(mData);
        }
       ));       
  }
  GetUrl() { 
   // return "https://localhost:44355/";
    //return "https://dosspay.com/";
   let urlx  =  window.location.protocol + "//" + window.location.host + "/";
   if(urlx.indexOf("localhost")!=-1){
    urlx = "https://localhost:44355/";
   }
    return urlx;
  }

}
export class RespData {
  status: string = "";
  message: string = "";
  data: string = "";
  UserID: string = "";
  ApiKey: string = "";
}
export class LooseObject {
  [key: string]: any
}


export class StorageService {
  private localStorage: Storage = localStorage;

  public set(key: any, data: any) {
      this.localStorage.setItem(key, JSON.stringify(data));
   }

  public get(key: string): any {
    let fromJson: any;
    fromJson = this.localStorage.getItem(key)
    return JSON.parse(fromJson);
   }

  public remove(key: any) {
      this.localStorage.removeItem(key);
  }
}
