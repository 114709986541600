import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';
import { AlertService } from '../alert.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-bulk-trans',
  templateUrl: './bulk-trans.component.html',
  styleUrls: ['./bulk-trans.component.css']
})
export class BulkTransComponent implements AfterViewInit, OnDestroy,OnInit {
  @ViewChild(DataTableDirective)
  dtElement!: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

 
  constructor(private Service: GService, private GPost: GPostService, private Show: AlertService, private router: Router) { }

  transList: any[] = [];
  formData: LooseObject = {};

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      lengthMenu: [10, 50, 100],
      dom: "Bfrtip"
    };
    this.formData.txtForeignID = this.Service.GenerateCSRF();
   
  }


  rerender(): void {
    try {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } catch (err) {
      console.log(err);
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fileChangeEvent(fileInput: any, model: string) {
    if (fileInput.target.files && fileInput.target.files[0]) {

      const reader = new FileReader();
      reader.onload = (e: any) => {        
            this.formData[model] = e.target.result;      
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  ValidateBulkMoMo() {
    this.Show.Loading();
    this.formData.action = "ValidateBulkMoMo";
    this.formData.url = "api/Trans";
  
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.transList = this.Service.ToJsonObject(data.data);
            this.rerender();
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          case "pending":
            this.Show.Alert(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

  ProcessTransaction() {
    this.Show.Loading();
    this.formData.action = "ProcessBulkMoMo";
    this.formData.url = "api/Trans";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();
      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);   
            this.Service.ReloadState(this.router);                  
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          case "pending":
            this.Show.Alert(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }


}
