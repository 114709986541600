import { Component, OnInit } from '@angular/core';
import { AlertService } from '../alert.service';
import { GService } from '../g.service';
import { GPostService, LooseObject } from '../gpost.service';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.css']
})
export class DashboardDetailsComponent implements OnInit {

  constructor(private GPost: GPostService, private Show: AlertService, private Service: GService) { }

  DashB: LooseObject = {};
  formData: LooseObject = {};
 
  ngOnInit(): void {
    this.GetDashboard();
  }
  ShowTransStatus(val: string) {
    return this.Service.ShowTransStatus(val);
  }
  ShowStatusClass(val: string) {
    return this.Service.ShowStatusClass(val);
  }
  GetDashboard() {
    this.Show.Loading();
    this.formData.action = "GetDashboard";
    this.formData.url = "api/Reports";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();
      if (this.GPost.IsObject(resp)) {
        let data: LooseObject = resp as LooseObject;
        switch (data.status) {
          case "success":
            this.DashB = JSON.parse(data.data);
            this.DashB.TransList = JSON.parse(this.DashB.TransList);          
          break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }else{
        this.Show.Alert("Invalid response data!");           
      }

    });
  }

}
