import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';
import {Asset} from '../asset.model';

@Component({
  selector: 'app-add-an-asset',
  templateUrl: './add-an-asset.component.html',
  styleUrls: ['./add-an-asset.component.css']
})
export class AddAnAssetComponent implements OnInit {

  constructor(private UserServive : UserService) { }

  ngOnInit(): void {
  }
   onAddAsset(assetData : Asset){
     this.UserServive.addAsset(assetData.AssetID, assetData.asset_price);
   }
}
