<div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <form 
        #userData="ngForm" 
        (ngSubmit)="Login(userData.value)"
        id="form"
        >
        <h3 id="fonxxx"> Register </h3>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              required
              ngModel
              name="first_name"
              placeholder="First name"
              id=""
            >
    
          </div>
          <div class="form-group">
          <input
          type="text"
          class="form-control"
          required
          ngModel
          name="last_name"
          placeholder="Last name"/>
  
          </div>
        <div class="form-group">
          <input
          type="email"
          class="form-control"
          required
          ngModel
          name="email"
          placeholder="Email"
          email
        />
      </div>
      <div class="form-group">
        <input
        type="tel"
        class="form-control"
        required
        ngModel
        name="phone"
        placeholder="Phone"
       
      />
    </div>
      <div class="form-group">
        <input
        type="password"
        class="form-control"
        required
        ngModel
        name="password"
        placeholder="Password"
      />
    </div>
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!userData.valid"
          >
           Sign Up
          </button>
          <h6>Already have an account? <a routerLink="/login" id="fonxxx">Log in</a></h6>
          </form>
          </div>
          </div>
          </div>
          