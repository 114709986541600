import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';

@Component({
  selector: 'app-add-asset',
  templateUrl: './add-asset.component.html',
  styleUrls: ['./add-asset.component.css'],
})
export class AddAssetComponent implements OnInit {

  constructor(private Service: GService, private GPost: GPostService, private Show: AlertService, private router: Router) { }
  formData: LooseObject = {};
 
  ngOnInit(): void {

  }

  SaveAsset() {
    this.Show.Loading();
    this.formData.action = "addAssets";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            this.Service.ReloadState(this.router);
            break;
          case "failed":
            this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

  onSelectFile(event: any) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.formData.Icon = event.target.result;
      }
    }
  }




}
