import { Component, OnInit } from '@angular/core';
import { AlertService } from '../alert.service';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EditUserComponent } from '../edit-user/edit-user.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(private GPost: GPostService, private Show: AlertService,private ModalService: NgbModal, private Service: GService) {

  }
  dtOptions: any = {};
  //dtTrigger: Subject<any> = new Subject();
  //dtElement: DataTableDirective | undefined;

  formData: LooseObject = {};
  userList: any[] = [];
  
  ngOnInit(): void {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        lengthMenu: [10,50,100],
        dom:"Bfrtip",
        buttons:["excel","print"]
      };
    this.GetUsers();
  }
  EditUser(val: any) {
    const modal = this.ModalService.open(EditUserComponent, { size: "lg" });
    modal.componentInstance.user = val; 
  }
  ShowStatus(val: string) {
   return this.Service.ShowStatus(val);
  }
  ShowStatusClass(val: string) {
   return this.Service.ShowStatusClass(val);
  }
  ResetPassword(val: any) {
    this.Show.Loading();
    this.formData.action = "ResetPassword";
    this.formData.url = "api/Users";
    this.formData.ID = val.ID;
    this.formData.UserIDX = val.UserIDX;
    this.GPost.Post(this.formData).subscribe((resp) => {
    this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            break;
          case "failed":
            this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

  GetUsers() {
    this.Show.Loading();
    this.formData.action = "GetUsers";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.userList = this.Service.ToJsonObject(data.data);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }


}
