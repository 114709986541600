<div class="card card-box mb-5">
    <div class="card-header alert-danger bg-red-lights">
      <div class="font-size-xl white" style="float:left;">
        Reset Password
      </div>
    </div>
    
    <div class="divider"></div>
    <div class="card-body">
        <div class="form-row">
            <div class="form-group col-md-3">
              Current Password
                </div>
                 <div class="form-group col-md-3">
            <input class="form-control"  type="password"  name="from" >     
            </div>                  
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              New Password
                </div>
                 <div class="form-group col-md-3">
            <input class="form-control"  type="password"  name="from" >     
            </div>                  
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              Confirm New Password
                </div>
                 <div class="form-group col-md-3">
            <input class="form-control" type="password"
                           name="from" >     
            </div>                  
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
                     <button class="btn btn-lg btn-second btn-block"> Reset Password </button>
            </div>                  
          </div>

         
                      
      
  </div>

  </div>
  