import { Component, OnInit } from '@angular/core';
import { LooseObject } from '../gpost.service';
import { GService } from '../g.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private LoginService: GService) { }

  LoginData: LooseObject = {};
  MenuControl: LooseObject = {};
  ngOnInit(): void {
    this.LoginData = this.LoginService.GetLogin() as LooseObject;
    this.MenuControl = JSON.parse(this.LoginData.MenuAccess);
  }


}
