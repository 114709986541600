<div class="row">
  <div class="col-lg-4">
    <div class="card card-box bg-premium-dark border-0 text-light mb-5">
      <div class="card-body">
        <div class="d-flex align-items-start">
          <div class="font-weight-bold">
            <small class="text-white-50 d-block mb-1 text-uppercase">Account Balance</small>
            <span class="font-size-xxl mt-1">GHS {{DashB.Balance}}</span>
          </div>
          <div class="ml-auto">
            <div class="bg-white text-center text-success font-size-xl d-50 rounded-circle">
              <i class="far fa-lightbulb"></i>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <i class="fas fa-lightbulb text-success"></i>
          <span class="text-white-50">The remaining balance in the virtual account</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="card card-box bg-midnight-bloom text-light mb-5">
      <div class="card-body">
        <div class="d-flex align-items-start">
          <div class="font-weight-bold">
            <small class="text-white-50 d-block mb-1 text-uppercase">Debit Transactions</small>
            <span class="font-size-xxl mt-1">GHS {{DashB.TransDebit}}</span>
          </div>
          <div class="ml-auto">
            <div class="bg-white text-center text-primary font-size-xl d-50 rounded-circle">
              <i class="far fa-snowflake"></i>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <i class="fas fa-arrow-down text-warning"></i>
         <!-- <span class="text-warning">7.4%</span>-->
          <span class="text-white-50">Total amount sent out to customers from the system</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="card card-box bg-plum-plate text-light mb-5">
      <div class="card-body">
        <div class="d-flex align-items-start">
          <div class="font-weight-bold">
            <small class="text-white-50 d-block mb-1 text-uppercase">Credit Transactions</small>
            <span class="font-size-xxl mt-1">GHS {{DashB.TransCredit}}</span>
          </div>
          <div class="ml-auto">
            <div class="bg-white text-center text-primary font-size-xl d-50 rounded-circle">
              <i class="fas fa-battery-three-quarters"></i>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <i class="fas fa-arrow-up text-success"></i>
         <!-- <span class="text-white">15.4%</span> -->
          <span class="text-white-50">Total amount received from customers into the system</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---->
<div class="row">
  <div class="col-xl-6">
    <div class="card card-box mb-5">
      <div class="card-header py-4">
        <div class="card-header--title">
          <small class="d-block text-uppercase text-black-50 mt-1">Targets</small>
          <b class="font-size-lg">Monthly statistics</b>
        </div>
      </div>
      <div class="divider"></div>
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item pt-2 pb-2">
            <div class="d-flex mb-1">
              <div>
                <div class="font-weight-bold">Payment Received</div>
              </div>
              <div class="ml-auto">
                <div class="font-size-xl font-weight-bold text-success">
                  GHS {{DashB.TransCreditM}}
                </div>
              </div>
            </div>
            <div class="progress-bar-sm progress-bar-animated-alt progress">
              <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 50%;"></div>
            </div>
            <div class="d-flex progress-bar--label mt-1 text-muted">
              <small class="text-dark">0</small>
              <div class="ml-auto">100%</div>
            </div>
          </li>
          <li class="list-group-item pt-2 pb-2">
            <div class="d-flex mb-1">
              <div>
                <div class="font-weight-bold">Payout to Customers</div>
              </div>
              <div class="ml-auto">
                <div class="font-size-xl font-weight-bold text-danger">
                  GHS {{DashB.TransDebitM}}
                </div>
              </div>
            </div>
            <div class="progress-bar-sm progress-bar-animated-alt progress">
              <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 50%;"></div>
            </div>
            <div class="d-flex progress-bar--label mt-1 text-muted">
              <small class="text-dark">0</small>
              <div class="ml-auto">100%</div>
            </div>
          </li>
          <li class="list-group-item pt-2 pb-2">
            <div class="d-flex mb-1">
              <div>
                <div class="font-weight-bold">Total Transaction Volume</div>
              </div>
              <div class="ml-auto">
                <div class="font-size-xl font-weight-bold text-info">
                  GHS {{DashB.TransVolM}}
                </div>
              </div>
            </div>
            <div class="progress-bar-sm progress-bar-animated-alt progress">
              <div class="progress-bar bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 50%;"></div>
            </div>
            <div class="d-flex progress-bar--label mt-1 text-muted">
              <small class="text-dark">0</small>
              <div class="ml-auto">100%</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="card-footer py-4 bg-light text-center">
        <button class="btn btn-first" type="button">
          <span class="btn-wrapper--icon">
            <i class="far fa-question-circle"></i>
          </span>
          <span class="btn-wrapper--label">
            Generate reports
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-xl-6">
    <div class="card card-box mb-5">
      <div class="list-group-item border-top-0 border-left-0 border-right-0 py-4 d-flex justify-content-between bg-white align-items-center">
        <i class="far fa-chart-bar font-size-xxl text-info"></i>
        <div class="flex-grow-1 pl-2">
          <h5 class="mt-0 mb-1">Recent Transactions</h5>
          <p class="card-text mb-0">
            A summary of recent 5 transactions
          </p>
        </div>
        <span class="pl-3">
          <button class="btn btn-sm btn-second pl-2 pr-2" type="button">
            <span class="btn-wrapper--icon">
              <i class="fas fa-cog"></i>
            </span>
          </button>
        </span>
      </div>
      <div class="card-header bg-white card-header-tab py-2 px-2">
        
      </div>
      <div class="card-body p-1">
        
        <table *ngIf="DashB.TransList.length" class="table table-hover table-striped table-bordered mb-5">
          <thead class="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Narration</th>
              <th scope="col">Debit</th>
              <th scope="col">Credit</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of DashB.TransList;let i=index">
              <td>{{i+1}}</td>
              <td>{{item.narration}}</td>
              <td>{{item.debit}}</td>
              <td>{{item.credit}}</td>
              <td><span class="badge badge-pill m-1 badge-{{ShowStatusClass(item.Statuz)}}">{{ShowTransStatus(item.Statuz)}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

